import { Link } from 'gatsby';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button/OutlinedButton';
// import CompactLogo from '../Images/CompactLogo';

const Header = ({ withRegister }) => (
  <header className="sticky top-0 bg-white z-50 h-20 flex items-center justify-center">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        {/* <div className="w-24 mr-3">
          <Link to="/">
            <CompactLogo />
          </Link>
        </div> */}
      </div>
      <div className="flex items-center mt-4 sm:mt-0">
      <Link className="px-4" to="/#">Home</Link>
        <Link className="px-4" to="/about">About</Link>
        {/* <a href="#" className="px-4" target="_blank" rel="norefferer">
          Webinars
        </a> */}
        <Link to="/submission" className="px-4">
          Submission
        </Link>
        {/* {withRegister ? (
          <div className="hidden md:block">
            <Link to="/register">
              <Button className="text-sm rounded-full" size="sm">
                Register Now
              </Button>
            </Link>
          </div>
        ) : null} */}
      </div>
    </div>
  </header>
);

export default Header;
