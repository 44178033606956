import React from 'react';
import Footer from './Footer';
import Header from './Header';

const Layout = ({ children, withRegister=false }) => {
  return (
    <>
      <div className=''>

      <Header withRegister={withRegister} />
      <main className="text-gray-900">{children}</main>
      </div>
      <Footer/>
    </>
  );
};

export default Layout;
