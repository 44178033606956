import React from 'react';
import Button from '../../components/Button/Button';

const primecolor = {
  color: '#0C41B8',
};

const Footer = () => (
  <footer className="bg-primary text-white">
    <div className="container mx-auto p-12 flex justify-between items-center">
      <h2 className="text-xl lg:text-2xl">
        Need Further Information?
        <br />
        Please don't hesitate to contact us!
      </h2>
      <a href="http://linktr.ee/DIGITECH2022" target="_blank">
        <Button className="text-primary" color="bg-white hover:bg-gray-200">Contact Us</Button>
      </a>
    </div>
  </footer>
);

export default Footer;
